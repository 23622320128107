import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  console.log("TIIIIIIITLE: ", page)

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} footer={footer} seo={{}}>
      {!page.hide_title_section && 
        <div className="bg-light" style={{backgroundImage:"url(" + page.title_section_image.url + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",  backgroundSize:"cover"}}>
          <div className="py-lg-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(3px)"}}>
            <div className="container py-5">
                <h3 className="text-white mb-0">{page.display_title.text}</h3>
            </div>
          </div>
        </div>
      }
      <SliceZone slices={page.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        hide_title_section
        display_title {
          text
        }
        title_section_image {
          url
        }
        body { 
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
					...PageDataBodyPageCover
					...PageDataBodyOneLineTitleAndText
					...PageDataBodyVideoAndText
					...PageDataBodyOrderedListWithDesc
					...PageDataBodyFeaturesTwoCols
					...PageDataBodyFeaturesThreeCols
					...PageDataBodyCallToAction
					...PageDataBodyAccordionFaq
					...PageDataBodyOurTeam
					...PageDataBodyTitleAndText
					...PageDataBodyEmbeddedHtml
					...PageDataBodyEmbeddedHtmlTwo
          ...PageDataBodyContactForm
          ...PageDataBodyCouponsForm
          ... on PrismicPageDataBodyJsonForm {
            primary {
              json_config
            }
          }
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(PageTemplate)
